<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <v-form @submit.prevent="handleSubmit(submitForm)" ref="form">
      <v-row>
        <v-col cols="12" sm="6" md="5" lg="3">
          <validation-provider v-slot="{ errors }" name="technician">
            <v-autocomplete
              v-model="config.technician"
              :items="technicians"
              item-text="cmdesc"
              item-value="cm4stamp"
              :label="$t('fields.technician')"
              :prepend-inner-icon="mdiAccountTie"
              :error-messages="errors"
              outlined
              dense
              clearable
              :loading="isLoading"
            />
          </validation-provider>
        </v-col>
        <v-col cols="12">
          <v-btn color="primary" type="submit" :loading="isLoading" :disabled="isLoading">
            {{ $t('aSave') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </validation-observer>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
  mdiAccountTie,
} from '@mdi/js';

export default {
  metaInfo() {
    return {
      title: this.$tc('config', 2),
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    config: {},
    mdiAccountTie,
  }),
  computed: {
    ...mapFields({ vuexConfig: 'config', isLoading: 'isLoading' }),
    ...mapFields('support', ['technicians']),
  },
  methods: {
    ...mapMutations(['setAlert']),
    ...mapActions('support', ['fetchTechnicians']),
    async submitForm() {
      this.isLoading = true;

      localStorage.setItem('config', JSON.stringify(this.config));

      this.setAlert({ msg: this.$t('wot.success'), alertType: 'success' });

      this.isLoading = false;
    },
  },
  async mounted() {
    this.isLoading = true;

    await this.fetchTechnicians();

    this.isLoading = false;

    if (this.vuexConfig) {
      this.config = this.vuexConfig;
    }
  },
};
</script>
